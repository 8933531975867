/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { get } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import { clientManager } from '../../actions/client_manager'

export const CourseCompletionProgressBar = ({ customer_course, variant, extra_styles, ...props }) => {

    extra_styles = extra_styles || {}
    if ( variant === "white" ) {
        extra_styles.progress_bar_style = progress_bar_style__white
    }
    
    return (
        <div css={container_style}>
          <div css={[progress_bar_style, get(extra_styles, "progress_bar_style")]}>
            <div css={[progress_bar_amount_style,
                       css`background-color: ${clientManager.getPrimaryColour()};
                           width: ${get(customer_course, "progress_percentage", 0)}%;`]}>
              &nbsp;
            </div>
          </div>
          <div css={progress_display_style}>
            {Math.floor(get(customer_course, "progress_percentage", 0))}% completed
          </div>
        </div>
    )
}

const container_style = css`
width: 100%;
text-align: left;
`

const progress_bar_style = css`
width: 100%;
height: 5px;
background-color: #eaeaea;
border-radius: 2px;
`

const progress_bar_style__white = css`
background-color: #fff;
`

const progress_bar_amount_style = css`
height: 5px;
border-radius: 2px;
`

const progress_display_style = css`
`

