import { ItemList } from '../../orm'

class FileImportList extends ItemList {
    getEntityKey() {
        return "file_import/process"
    }

    process(values) {
        return this.saveNewObject(values)
    }
}

export const fileImportList = new FileImportList("file_import/process")
