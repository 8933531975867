/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get } from 'lodash'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../../emotion/theme'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from '../Loading'
import BlueButton from '../layout/BlueButton'
import { CourseCompletionProgressBar } from './CourseCompletionProgressBar'
import { customerCourseForPackageList } from '../../actions/customer_course'
import { faPlayCircle, faFileAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import { courseList } from '../../actions/course'
import { featuredCoursePackageList } from '../../actions/featured_course_package'
import { withRouter } from 'react-router'
import Pluralize from 'react-pluralize'
import { Separator } from '../layout/Separator'
import { clientManager } from '../../actions/client_manager'
import BusyMask from '../BusyMask'
import ReplacementText from '../ReplacementText'

class FeaturedCourse extends Component {

    componentDidMount() {
        const { dispatch, selected_course_id, featured_course_package_id } = this.props
        dispatch(courseList.ensureObjectLoaded(selected_course_id))
        if ( ! selected_course_id ) {
            dispatch(featuredCoursePackageList.fetchListIfNeeded())
        }
        
        if ( featured_course_package_id ) {
            dispatch(customerCourseForPackageList.updateListFilter({course_package: featured_course_package_id}))
            dispatch(customerCourseForPackageList.fetchListIfNeeded())
        }
    }

    componentDidUpdate(prev_props) {
        const { dispatch, selected_course_id, featured_course_package_id, featured_course_package_filter } = this.props
        if ( selected_course_id != prev_props.selected_course_id ) {
            dispatch(courseList.ensureObjectLoaded(selected_course_id))
        }
        if ( ! selected_course_id ) {
            dispatch(featuredCoursePackageList.fetchListIfNeeded())
        }
        if ( featured_course_package_id && featured_course_package_filter.course_package !== featured_course_package_id ) {
            dispatch(customerCourseForPackageList.updateListFilter({course_package: featured_course_package_id}))
            dispatch(customerCourseForPackageList.fetchListIfNeeded())
        }
    }
    
    onStartLearning = () => {
        const { featured_course_package_id } = this.props
        const { history } = this.props
        history.push(`/course/${featured_course_package_id}`)
    }
    
    render() {
        const { is_loading, featured_course_package_id, featured_course_package, customer_course } = this.props

        const client_pre_header_style = css`color: ${clientManager.getPrimaryColour()};`
        const client_info_icon_style = css`color: ${clientManager.getPrimaryColour()};`

        const has_started = get(customer_course, "progress_percentage", 0) > 0
        const is_complete = get(customer_course, "is_complete", false)

        if( ! featured_course_package_id ) {
            return null
        }
        
        if ( ! get(featured_course_package, ["course", "banner_image", "original_download_url"]) ) {
            return <BusyMask loading={true} />
        }
        
        return (
            <div css={ [course_banner_container, css`background-image: url(${(featured_course_package && featured_course_package.course.banner_image && featured_course_package.course.banner_image.original_download_url)});`] }>

              <Container css={container}>
                { is_loading && <Loading /> }

                <div css={course_info_style}>
                  
                  { ! is_loading && featured_course_package && 
                    <div>
                      <div css={[pre_header, client_pre_header_style]}>
                        { is_complete && "COMPLETE" }
                        { ! is_complete && has_started && "IN PROGRESS" }
                        { ! has_started && "FEATURED COURSE" }
                      </div>
                      <Separator variant="h15" />

                      <div css={header}>
                        {featured_course_package.name}
                      </div>
                      <Separator variant="h20" />

                      <div css={info_text}>
                        <div css={info_text_row}>
                          <div css={icon_style}>
                            <FontAwesomeIcon css={[info_icon, client_info_icon_style]} icon={faPlayCircle} />
                          </div>
                          <ReplacementText
                              placeholder="video module"
                              text={ featured_course_package && featured_course_package.video_replacement_text }
                              value={ featured_course_package.course.num_videos }
                          />
                        </div>
                        <Separator variant="h20" />

                        <div css={info_text_row}>
                          <div css={icon_style}>
                            <FontAwesomeIcon css={[info_icon, client_info_icon_style]} icon={faFileAlt} />
                          </div>
                          <ReplacementText
                              placeholder="assessment"
                              text={ featured_course_package && featured_course_package.assessment_replacement_text }
                              value={ featured_course_package.course.num_assessments }
                          />
                        </div>
                        <Separator variant="h20" />

                        <div css={info_text_row}>
                          <div css={icon_style}>
                            <FontAwesomeIcon css={[info_icon, client_info_icon_style]} icon={faClock} />
                          </div>
                          {Math.round(featured_course_package.course.duration_minutes)} minutes
                        </div>
                        <Separator variant="h20" />

                        <div css={[info_text_row, progress_bar_style]}>
                          <CourseCompletionProgressBar customer_course={customer_course} variant="white" />
                        </div>
                        <Separator variant="h20" />

                      </div>


                      <div css={start_learning_button_style_container}>
                        <BlueButton onClick={this.onStartLearning} css={start_learning_button_style} size="lg">
                          { is_complete && "REOPEN" }
                          { ! is_complete && has_started && "CONTINUE COURSE" }
                          { !has_started && "START LEARNING" }
                        </BlueButton>
                      </div>
                    </div>
                  }
                </div>

              </Container>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { selected_course_id } = props
    const selected_course = selected_course_id && courseList.getObject(selected_course_id)
    const featured_course_packages = !selected_course_id && featuredCoursePackageList.getVisibleObjects()
    const featured_course_package = selected_course || head(featured_course_packages)
    const customer_course = featured_course_package && head(customerCourseForPackageList.getVisibleObjects())
    
    return {
        selected_course_id,
        customer_course,
        featured_course_package,
        featured_course_package_id: featured_course_package && featured_course_package.id,
        featured_course_package_filter: customerCourseForPackageList.getFilter(),
        is_loading: featuredCoursePackageList.isLoading() || courseList.isLoading()
    }
}

export default withRouter(connect(mapStateToProps)(FeaturedCourse))

const container = css(theme.mq({
    display: "flex",
    alignItems: "center",
    height: "100%",
    marginLeft: ["auto", "0px"],
    marginRight: ["auto", "0px"],
    paddingLeft: ["15px", "0px"],
    paddingRight: ["15px", "0px"]
}))

const course_banner_container = css(theme.mq({
    height: ["640px", "auto"],
    backgroundBlendMode: "darken",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%"
}))

const pre_header = css(theme.mq({
  fontSize: ["24px","20px"],
  padding:["0px","12px"],
  lineHeight: "1.1",
  fontWeight: "500"
}))

const header = css(theme.mq({
    color: "#ffffff",
    fontSize: ["40px", "20px"],
    fontFamily: "Lato",
    marginBottom: "10px",
    fontWeight: "700",
    lineHeight: "1.1"
}))

const info_text = css(theme.mq({
  marginLeft: ["5px"],
  fontSize: ["18px"],
  color: "#ffffff",
  margin: "0 0 10px",
  justifyContent: "spaceBetween"
}))

const info_icon = css`
margin-right: 5px;
`

const course_info_style = css(theme.mq({
    width: ["360px", "100%"],
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    backgroundColor: "rgb(0,0,0,0.4)"
}))

const info_text_row = css(theme.mq({
display: "flex",
}))


const icon_style = css`
width: 15px;
margin-right: 5px;
display: flex;
align-items: center;
`

const start_learning_button_style_container = css(theme.mq({
    width: ["66%", "100%"],
    whiteSpace: ["initial", "nowrap"],
    paddingBottom: ["0px", "15px"]
}))

const start_learning_button_style = css`
width: 100%;
border-radius: 2px;
`

const progress_bar_style = css(theme.mq({
width: ["66%", "100%"]
}))

