/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, map, size } from 'lodash'
import { Link } from 'react-router-dom'
import Timestamp from '../../components/Timestamp'
import { adminClientList } from '../actions/admin_client'
import CommonTable from '../../components/CommonTable'
import { adminCoursePackageList } from '../actions/admin_course_package'
import {
    adminUserInCoursePackageList,
    adminUserNotInCoursePackageList,
    adminUserCoursePackageManagerList
} from '../actions/admin_course_package_users'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminClientGroups from './AdminClientGroups'
import AdminClientForm from './AdminClientForm'
import AdminCoursePackages from './AdminCoursePackages'
import BusyMask from '../../components/BusyMask'
import { InlineIcon } from '../../components/layout/InlineIcon'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'

class AdminCoursePackageUsers extends Component {

    componentDidMount() {
        const { dispatch, client_id, course_package_id } = this.props
        dispatch(adminClientList.ensureObjectLoaded(client_id))
        dispatch(adminCoursePackageList.ensureObjectLoaded(course_package_id))
        dispatch(adminUserInCoursePackageList.updateListFilter({in_course_package:course_package_id, client: client_id}))
        dispatch(adminUserNotInCoursePackageList.updateListFilter({not_in_course_package:course_package_id, client: client_id}))

        dispatch(adminUserInCoursePackageList.fetchListIfNeeded())
        dispatch(adminUserNotInCoursePackageList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, client_id, course_package_id } = this.props
        dispatch(adminClientList.ensureObjectLoaded(client_id))
        dispatch(adminCoursePackageList.ensureObjectLoaded(course_package_id))
        dispatch(adminUserInCoursePackageList.fetchListIfNeeded())
        dispatch(adminUserNotInCoursePackageList.fetchListIfNeeded())
    }

    onAddGroupUsers = (group) => {
        const { dispatch, client_id, course_package_id } = this.props
        if (! window.confirm(`Add all users from group ${group.name}?`) ) {
            return
        }
        
        const on_done = function(json) {
            dispatch(adminUserNotInCoursePackageList.invalidateList())
            dispatch(adminUserInCoursePackageList.invalidateList())
            dispatch(adminCoursePackageList.invalidateObject(course_package_id))
        }
        dispatch(adminUserCoursePackageManagerList.addGroupToCoursePackage(client_id, group.id, course_package_id, on_done))
    }
    
    onAddUser = (user) => {
        const { dispatch, client_id, course_package_id } = this.props

        const on_done = function(json) {
            dispatch(adminUserNotInCoursePackageList.invalidateList())
            dispatch(adminUserInCoursePackageList.invalidateList())
            dispatch(adminCoursePackageList.invalidateObject(course_package_id))
        }
        dispatch(adminUserCoursePackageManagerList.addUserToCoursePackage(client_id, user.id, course_package_id, on_done))
    }

    onRemoveUser = (user) => {
        const { dispatch, client_id, course_package_id } = this.props

        const on_done = function(json) {
            dispatch(adminUserInCoursePackageList.invalidateList())
            dispatch(adminUserNotInCoursePackageList.invalidateList())
            dispatch(adminCoursePackageList.invalidateObject(course_package_id))
        }
        dispatch(adminUserCoursePackageManagerList.removeUserFromCoursePackage(client_id, user.id, course_package_id, on_done))
    }
        
    getUserInCoursePackageCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'phone_number':
                return item.phone_number
            case 'email':
                return item.email
            case 'action':
                return <InlineIcon icon_name="remove" onClick={() => this.onRemoveUser(item)} />
            default:
                return <span>Unknown header {header_key}</span>
        }
    }

    getUserNotInCoursePackageCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'phone_number':
                return item.phone_number
            case 'email':
                return item.email
            case 'action':
                return <InlineIcon icon_name="add" onClick={() => this.onAddUser(item)} />
            default:
                return <span>Unknown header {header_key}</span>
        }
    }

    onDone = () => {
        const { history, client_id, course_package_id } = this.props
        history.push(`/admin/client/${client_id}/course_package/${course_package_id}`)
    }

    renderAddGroup() {
        return (
            <div>
            </div>
        )
    }

    render() {
        const { client_id, is_busy, is_loading, client, course_package,
                page, breadcrumbs, user_headers, users_in_course_package, users_not_in_course_package } = this.props

        const title = `Manage users for course ${get(course_package, "name")}`
        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              { (!client_id || client.id) &&
                <Row>
                  <Col md="6">
                    <Card title="Users in the course">
                      <CommonTable
                        is_loading={ is_loading }
                        empty_message={`No added users`}
                        headers={ user_headers }
                        items={ users_in_course_package }
                        getCellValue={ this.getUserInCoursePackageCellValue }
                        item_list={adminUserInCoursePackageList}
                      />
                    </Card>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col>
                        <Card>
                          <AdminClientGroups title="Add all users from a group"
                                             client_id={client_id}
                                             can_edit={false}
                                             onSelect={this.onAddGroupUsers}
                          />
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card title="Users not in the course">
                          <CommonTable
                            is_loading={ is_loading }
                            empty_message={`No available users`}
                            headers={ user_headers }
                            items={ users_not_in_course_package }
                            getCellValue={ this.getUserNotInCoursePackageCellValue }
                            item_list={adminUserNotInCoursePackageList}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }

              <Button onClick={this.onDone}>
                Done
              </Button>
              
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const { page } = props
    const client_id = get(props, ["match", "params", "client_id"], null)
    const course_package_id = get(props, ["match", "params", "course_package_id"], null)
    const client = client_id && adminClientList.getObject(client_id)
    const course_package = course_package_id && adminCoursePackageList.getObject(course_package_id)
    const breadcrumbs = [ {label: "Clients",
                           url: "/admin/clients"},
                          {label: client && client.name,
                           url: client &&`/admin/client/${client_id}`},
                          {label: "Courses",
                           url: `/admin/client/${client_id}/course_packages`},
                          {label: course_package && course_package.name,
                           url: course_package &&`/admin/client/${client_id}/course_package/${course_package_id}`},
                          {label: "Users",
                           url: course_package &&`/admin/client/${client_id}/course_package/${course_package_id}/users`}]
    const users_in_course_package = adminUserInCoursePackageList.getVisibleObjects()
    const users_not_in_course_package = adminUserNotInCoursePackageList.getVisibleObjects()
    return {
        client_id,
        client,
        course_package_id,
        course_package,
        page,
        breadcrumbs,
        is_loading: adminClientList.isLoading() || adminCoursePackageList.isLoading() || adminUserInCoursePackageList.isLoading() || adminUserNotInCoursePackageList.isLoading(),
        is_busy: adminClientList.getIsSavingObject() || adminCoursePackageList.getIsSavingObject() || adminUserInCoursePackageList.getIsSavingObject() || adminUserNotInCoursePackageList.getIsSavingObject(),
        users_in_course_package,
        users_not_in_course_package,
        user_headers: {
            last_name: { name: 'Last name', column_size: 2},
            first_name: { name: 'First name', column_size: 2},
            email: { name: 'Email Address', column_size: 2},
            action: { name: '', column_size: 1 }
        }
    }
}
export default connect(mapStateToProps)(AdminCoursePackageUsers)

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`
