/** @jsx jsx */
import { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { jsx, css,  Global } from '@emotion/core'
import { map, has, isEmpty, keys as _keys, isArray } from 'lodash'
import WrappingBusyMask from './WrappingBusyMask'
import loading_icon from '../images/loading.gif'
import { default_theme as theme } from '../emotion/theme'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Pagination from './Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

class CommonTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            header_sizes: [],
            visible_rows: [],
            selected_row: null,
        }
    }

    handleRowClick = (e, row_id) => {
        const { onRowClick } = this.props
        e.preventDefault()
        if (onRowClick) {
            onRowClick(row_id)
        }
    }

    refresh = () => {
        const { item_list, dispatch } = this.props
        dispatch(item_list.invalidateList())
        dispatch(item_list.fetchListIfNeeded())
    }

    renderCell(item, header_key, index, row_index, current_state) {
        const { headers, renderCell, getCellValue, editable } = this.props

        let rendered_result = undefined
        
        if ( renderCell ) {
            rendered_result = renderCell(header_key, item, headers[header_key].column_size || 1, row_index, index, current_state, editable)
        }
        if ( rendered_result === undefined ) {
            let cell_value = undefined
            if ( getCellValue ) {
                cell_value = getCellValue(header_key, item, index, row_index)
            }
            if ( cell_value === undefined ) {
                cell_value = item[header_key]
            }

            rendered_result = (
                <div css={ truncate }>
                  {cell_value}
                </div>
            )
        }
        return (
            <td key={index} css={ css`:has(div):has(.checkbox) {display:flex; align-items: center; justify-content: center;}` }>
              {rendered_result}
            </td>
        )
    }

    renderRow = (item, row_index, is_editing) => {
        const { headers, onRowClick, custom_row_height } = this.props
        const keys = _keys(headers)
        let row_id = item.id

        const current_state = (has(this.state, row_id) && this.state[row_id]) || false

        const has_additional_items = has(item, 'additional_items') && isArray(item.additional_items)

        return (
            <Fragment>
              <tr key={row_index} onClick={ (e) => this.handleRowClick(e, item.id) }>
                {map(keys, (header_key, index) => {
                    if (headers[header_key] === undefined) {
                        return null
                    }
                    return this.renderCell(item, header_key, index, row_index, current_state)
                    
                })}
              </tr>
              { has_additional_items && this.renderAdditionalRows(item.additional_items) }
            </Fragment>
        )
    }

    renderAdditionalRows = (additional_items) => {
        return (
            <Fragment>
              {map(additional_items, (item, row_index) => {
                  return this.renderRow(item, row_index, false)
              })}
            </Fragment>
        )
    }

    renderEmptyRow = () => {
        const { empty_message } = this.props
        return (
            <tr>
              <td colSpan="50">
                <em>{ empty_message }</em>
              </td>
            </tr>
        )
    }

    sortOnHeader = (key) => {
        const { sortOnHeader, dispatch, item_list } = this.props
        if ( sortOnHeader ) {
            sortOnHeader(key)
        }
        if ( ! item_list ) {
            return
        }

        const filter = item_list.getFilter()
        if (key !== 'action' && key !== 'admin_email') {
            let sort_by = key
            if (has(filter, 'order_by') && filter.order_by === key) {
                sort_by = '-' + key
            }
            dispatch(item_list.updateListFilter({'order_by': sort_by}))
        }
    }

    render() {
        const {
            headers,
            items,
            item_list,
            is_loading,
            rows_displayed,
            add_row,
            edit_row
        } = this.props

        const that = this
        const keys = _keys(headers)

        return (
            <WrappingBusyMask is_loading={is_loading && !isEmpty(items)}>
              <Global styles={global_styles} />
              <div>
                <Table bordered responsive css={table_style}>
                  <thead css={thead_style}>
                    <tr>
                      {keys && map(keys, (header_key, index) => {
                           return (
                               <th css={th_style} key={index}>
                                 <div onClick={ (e) => that.sortOnHeader(header_key) }
                                         css={css`cursor:pointer;`}>
                                   { headers[header_key].name }
                                 </div>
                               </th>
                           )
                      })}
                    </tr>
                  </thead>
                  <tbody css={tbody_style}>
                    { !is_loading && (add_row === undefined || add_row === null) && isEmpty(items) &&
                      this.renderEmptyRow()
                    }

                    { add_row !== undefined && add_row !== null && 
                      this.renderRow(add_row, -1)
                    }
                    {map(items, (item, row_index) => {
                         const is_editing = edit_row && edit_row.id === item.id
                         return this.renderRow(item, row_index, is_editing)
                    })}
                    
                  </tbody>
                  
                </Table>
                { item_list &&
                  <Pagination listKey={ item_list.listKey }
                              item_list={item_list}
                              filters={ item_list.getFilter() } />
                }

              </div>
            </WrappingBusyMask>
        )
    }
}

function mapStateToProps(state, props) {
    const { is_loading } = props
    return {
        is_loading: is_loading || (props.item_list && props.item_list.isLoading())
    }
}

export default connect(mapStateToProps)(CommonTable)

const global_styles = css`
    .table thead {
    background-color: ${theme.colors.page_background};
    }

    .table thead th {
    font-weight: 500;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid rgb(217,217,217)
    }

    .table-bordered td {
    border-top: 1px solid rgb(217,217,217);
    border-bottom: 1px solid rgb(217,217,217);
    border-left: 0px;
    border-right: 0px;
    vertical-align: top;

    }

    .table-responsive table.table {

    @media(max-width: ${theme.breakpoint_mobile}) {
    overflow-x: auto;
    }
    @media(min-width: ${theme.breakpoint_desktop}) {
    overflow-x: hidden;
    width: 90%;
    }

    }

`

const table_style = css`
    border-radius: 4px;
    margin-bottom: 0px;

`

const thead_style = css`
    color: ${theme.colors.secondary};
`

const th = css`
    display: flex;
`

const th_style = css`
    text-align: left;
`

const tr = css`
    display: flex;
    flex: 1;
    border-bottom: 1px solid ${theme.colors.gray2};
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
`

const tbody_style = css`
    border-left: 1px solid rgb(217,217,217);
    border-right: 2px solid rgb(217,217,217);
    border-bottom: 1px solid rgb(217,217,217);
`

const td = css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const truncate = css`
    display: flex;
`

const loading_container = css`
    position: absolute; 
    z-index:900; 
    background-color: rgba(255, 255, 255, 0.8); 
    width: 100%; 
    height: 100%;
    display: flex;
    justify-content: center; 
    align-items: center;
`

const loading = css`
    width: 32px;
    height: 32px;
`

const clickable_icon = css`
    cursor: pointer;
`

const tester = css`

`
