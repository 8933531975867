/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has, get } from 'lodash'

import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import DefaultButton from '../../components/DefaultButton'
import TableFilter from '../../components/TableFilter'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import FileUploader from '../../components/form/FileUploader'
import Modal from '../../components/Modal'
import { Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { adminUserList } from '../actions/admin_user'
import { adminClientList } from '../actions/admin_client'
import AdminUsersFileImport from './AdminUsersFileImport'

class AdminUsers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            displayImportModal: false,
        }
    }

    componentDidMount() {
        const { dispatch, client_id } = this.props
        if ( client_id ) {
            dispatch(adminUserList.updateListFilter({client: client_id}))
            dispatch(adminUserList.fetchListIfNeeded())
            dispatch(adminClientList.ensureObjectLoaded(client_id))
        }
    }

    componentDidUpdate(old_props) {
        const { dispatch, user_list_filter, client_id } = this.props
        if ( client_id ) {
            if ( user_list_filter.client != client_id ) {
                dispatch(adminUserList.updateListFilter({client: client_id}))
            }
            dispatch(adminClientList.ensureObjectLoaded(client_id))
            dispatch(adminUserList.fetchListIfNeeded())
        }
    }

    onEditUser = (evt, item) => {
        const { history, client_id } = this.props
        history.push({
            pathname: `/admin/client/${client_id}/user/${item.id}`
        })
    }

    onUpdateFilter = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminUserList.updateListFilter(filter_values))
    }

    onAddUser = () => {
        const { history, client_id } = this.props
        history.push(`/admin/client/${client_id}/user`)
    }

    onImportUsers = () => {
        this.toggleImportUsers()
    }

    onSuccess = (file, response) => {
        console.log('success')
    }

    onFailure = () => {
        console.log('failed')
    }

    toggleImportUsers = () => {
        const { displayImportModal } = this.state
        this.setState({displayImportModal: !displayImportModal})
    }

    sortOnHeader = (key) => {
        const { list_filter, dispatch } = this.props
        if (key !== 'action') {
            let sort_by = key
            if (list_filter.order_by === key) {
                sort_by = '-' + key
            }
            dispatch(adminUserList.updateListFilter({order_by:sort_by}))
        }
    }

    getUserCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'phone_number':
                return item.phone_number
            case 'email':
                return item.email
            case 'enabled':
                return (
                <input
                    type="checkbox"
                    checked={ item.enabled }
                    className="checkbox"
                    css={ css`height:1.4rem;` }
                    readonly={ true }
                />
                )
            case 'action':
                return (
                <Button onClick={(evt) => this.onEditUser(evt, item)}>
                  Edit
                </Button>
                )
                
            default:
                return <span>Unknown header {header_key}</span>
        }
    }

    renderImportModal = () => {
        const { client_id } = this.props
        return (
            <AdminUsersFileImport
                client_id={ client_id }
                onCloseModal={ this.toggleImportUsers }
            />
        )
    }

    render() {
        const { is_loading,
                upload_relative_url,
                user_headers,
                users,
                list_filter,
                client,
                breadcrumbs,
        } = this.props
        const {
            displayImportModal,
            displaySuccessModal,
            displayFailModal,
        } = this.state
        
        return (
            <AdminMainLayout title={`Users for ${get(client, "name")}`} breadcrumbs={breadcrumbs}>
              <Card>
                <TableHeader>
                  <TableFilter
                      updateOnChange={ this.onUpdateFilter }
                      form="ADMIN_USER_FILTER_FORM"
                  />
                  <div css={ add_container }>
                    <div css={ css`padding-left:15px;` }>
                      <DefaultButton
                          label="Import Users"
                          type="button"
                          light={ true }
                          onClickButton={ this.onImportUsers }
                      />
                    </div>
                    <div css={ css`padding-left:15px;` }>
                      <DefaultButton
                          label="Add User"
                          type="button"
                          light={ true }
                          onClickButton={ this.onAddUser }
                      />
                    </div>
                  </div>
                </TableHeader>
                { ! is_loading && 
                  <CommonTable
                      is_loading={ is_loading }
                      empty_message={`There are no users for ${get(client, "name")}`}
                      headers={ user_headers }
                      items={ users }
                      getCellValue={ this.getUserCellValue }
                      sortOnHeader={ this.sortOnHeader }
                      item_list={adminUserList}
                  />
                }
                
                <TableFooter>
                </TableFooter>
              </Card>
              { displayImportModal && this.renderImportModal() }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const client_id = get(props, ["match", "params", "client_id"], null)
    const users = adminUserList.getVisibleObjects()
    const client = client_id && adminClientList.getObject(client_id)

    const breadcrumbs = [ {label: "Clients",
                           url: client &&`/admin/clients`},
                          {label: client && client.name,
                           url: client &&`/admin/client/${client_id}`},
                          {label: "Users",
                           url: client &&`/admin/client/${client_id}/users`}
                        ]
    
    return {
        list_filter: adminUserList.getFilter(),
        users,
        client_id,
        client,
        user_headers: { 
           last_name: { name: 'Last name', column_size: 2},
            first_name: { name: 'First name', column_size: 2},
            phone_number: { name: 'Phone Number', column_size: 2},
            email: { name: 'Email Address', column_size: 2},
            created_at: { name: 'Date Created', column_size: 2},
            enabled: { name: 'Enabled', column_size: 2},
            action: { name: 'Actions', column_size: 1 },
        },
        user_list_filter: adminUserList.getFilter(),
        is_loading: adminUserList.isLoading(),
        upload_relative_url: 'file_import/',
        breadcrumbs
    }
}
export default withRouter(connect(mapStateToProps)(AdminUsers))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
`

const add_container = css`
display: flex;
align-items: center;
`

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
text-align: center;
`
